<template>
  <section
    v-if="prevItemInfo"
    class="lada-item-card"
  >
    <header class="lada-item-card__header">
      <a-row
        type="flex"
        justify="space-between"
      >
        <a-col>
          {{ release.title }}
        </a-col>
        <a-col v-if="itemId">
          <router-link :to="`/page/Item/${itemId}`">{{ $t('lada.about') }}</router-link>
        </a-col>
      </a-row>
    </header>

    <section class="lada-item-card__section">
      <a-row :gutter="30">
        <a-col :span="12">
          <h4>{{ $t('lada.previousData') }}</h4>
          <div
            v-for="key of fieldKeys"
            :key="key"
          >
            <h5>
              {{ getLabel('Item', key) }}
            </h5>
            <field-display
              :field="getFieldConfig('Item', key)"
              :value="prevItemInfo.data[key]"
            />
          </div>
        </a-col>
        <a-col :span="12">
          <h4>{{ $t('lada.newData') }}</h4>
          <div
            v-for="key of fieldKeys"
            :key="key"
          >
            <div v-if="release?.[key]">
              <h5>
                {{ getLabel('Item', key) }}
              </h5>
              <div v-if="key === 'cars'">
                <ref-autocomplete2
                  v-model="release.cars"
                  :config="getFieldConfig('Item', 'cars')"
                />
              </div>
              <div v-else-if="key === 'title'">
                <edit-form-string v-model="release.title" />
              </div>
              <field-display
                v-else
                :field="getFieldConfig('Item', key)"
                :value="release[key]"
              />
            </div>
          </div>
        </a-col>
      </a-row>
      <div class="lada-item-card__buttons">
        <a-button
          type="primary"
          :disabled="release.status !== statuses['new']"
          @click="$emit('updateStatus', data.id, statuses['accepted'], filteredData)"
        >
          {{ $t('entity.save') }}
        </a-button>
        <a-button
          type="danger"
          :disabled="release.status !== statuses['new']"
          @click="$emit('updateStatus', data.id, statuses['rejected'], filteredData)"
        >
          {{ $t('entity.reject') }}
        </a-button>
      </div>
    </section>
  </section>
</template>

<script>
import TABLE_QUERY from '@/queries/table';
import store from '@/store';
import FormConfigService from '@/services/FormConfigService';
import RefAutocomplete2 from '@/components/edit-form/fields/ref/RefAutocomplete2';
import EditFormString from '@/components/edit-form/fields/EditFormString';
import FieldDisplay from '../FieldDisplay';

const statuses = {
  accepted: 'ACCEPTED',
  rejected: 'REJECTED',
  new: 'NEW',
};

export default {
  name: 'LadaItemCard',

  components: {
    FieldDisplay,
    RefAutocomplete2,
    EditFormString,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  apollo: {
    prevItemInfo: {
      ...TABLE_QUERY,
      loadingKey: 'loading',
      fetchPolicy: 'network-only',
      variables() {
        return {
          type: 'Item',
          page: 0,
          pageSize: 999,
          filters: [
            {
              field: 'partnumber',
              operator: 'ILIKE',
              value: this.release.partnumber,
            },
          ],
        };
      },
      skip() {
        return !this.release.partnumber;
      },
      update({ table }) {
        return table.documents[0];
      },
      error(error) {
        this.emitError(this.$t('entity.error.getTable'), error.message);
      },
    },
  },
  data() {
    return {
      isLoading: false,
      release: this.data.data,
      statuses,
    };
  },

  computed: {
    itemId() {
      return this?.prevItemInfo?.id;
    },
    fieldKeys() {
      if (!this?.prevItemInfo?.data) return [];
      const removedKeys = ['id'];
      return Object.keys(this.prevItemInfo.data).filter((key) => !removedKeys.includes(key));
    },
    filteredData() {
      const { id: _, ...rest } = this.release;
      return rest;
    },
  },

  watch: {
    data() {
      this.release = { ...this.data.data };
    },
  },

  methods: {
    getFieldConfig(type, name) {
      const field = store.state.meta.entities
        .find((e) => e.name === type)
        .fields.find((f) => f.name === name);

      return field || { name, renderer: 'string' };
    },
    getLabel(entity, name) {
      return (
        FormConfigService.getFormConfig(entity).locale[store.state.lang].fields[name]?.label || name
      );
    },
  },
};
</script>

<style lang="scss">
.lada-item-card {
  background-color: #f9f9f9;
  width: 100%;

  .ant-form-item-control {
    line-height: 32px;
  }

  &__header {
    padding: 20px 22px;
    margin-bottom: 25px;
    font-weight: 500;
    background: rgba(0, 0, 0, 0.05);
  }

  &__section {
    padding: 0 22px 20px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
  }

  // .ant-collapse-borderless >
  .ant-collapse-item:last-child {
    border-bottom: none;
  }
}
</style>
